import React from "react"
import { Link, graphql } from "gatsby"
import Seo from "../components/SEO"
import Hero from "../components/Hero/Hero"
import BGImg from "../components/LargeBGImg/LargeBGImg"
import ImageWithHeading from "../components/ImageWithHeading/ImageWithHeading"
import ImageWithText from "../components/ImageWithText/ImageWithText"
import TwoColumn from "../components/TwoColumn/TwoColumn"
import ImageGrid from "../components/ImageGrid/ImageGrid"

const PersonalLife = ({ data }) => {

  // Grab variable from .md file

  // Hero Sections
  const hero = data.hero.edges[0].node.frontmatter.heroImage.childImageSharp.gatsbyImageData;
  const heroAlt = data.hero.edges[0].node.frontmatter.heroImageAlt;
  const identifier = data.hero.edges[0].node.frontmatter.identifier;
  const heroDescription = data.hero.edges[0].node.frontmatter.description;

  // Full Width Background With Title  Sections
  const childhoodContent = data.largeBGImg.edges.filter(item => item.node.frontmatter.title === 'Childhood')
  const familyHistorContent = data.largeBGImg.edges.filter(item => item.node.frontmatter.title === 'Family History')
  const storyHistorContent = data.largeBGImg.edges.filter(item => item.node.frontmatter.title === 'Story')
  const collegeContent = data.largeBGImg.edges.filter(item => item.node.frontmatter.title === 'College')
  const familyFourContent = data.largeBGImg.edges.filter(item => item.node.frontmatter.title === 'Family')

  // Two Column Sections
  const twoColumnHeritageTitle = data.twoColumn.edges.filter(item => item.node.frontmatter.section === 'heritagetitle1')
  const twoColumnHeritage = data.twoColumn.edges.filter(item => item.node.frontmatter.section === 'heritage')
  const twoColumnSpringboard = data.twoColumn.edges.filter(item => item.node.frontmatter.section === 'springboard')
  const twoColumnInternship = data.twoColumn.edges.filter(item => item.node.frontmatter.section === 'internship')
  const twoColumnNyNj = data.twoColumn.edges.filter(item => item.node.frontmatter.section === 'nytonj')
  const nynjComponent = data.imageWithText.edges.filter(item => item.node.frontmatter.identifier === 'nynj')
  const twoColumnDetroit = data.twoColumn.edges.filter(item => item.node.frontmatter.section === 'detroit')
  const twoColumnEconomicDepths = data.twoColumn.edges.filter(item => item.node.frontmatter.section === 'economicDepths')
  const twoColumnPhoneCall = data.twoColumn.edges.filter(item => item.node.frontmatter.section === 'phonecall')
  const jeffreyAndGary = data.twoColumn.edges.filter(item => item.node.frontmatter.section === 'jeffreyAndGary')
  const aFoundationBuilt = data.twoColumn.edges.filter(item => item.node.frontmatter.section === 'aFoundationBuilt')
  const aFullLife = data.twoColumn.edges.filter(item => item.node.frontmatter.section === 'aFullLife')


  const msuComponent = data.imageWithText.edges.filter(item => item.node.frontmatter.identifier === 'msu')
  const weddingComponent = data.imageWithText.edges.filter(item => item.node.frontmatter.identifier === 'wedding')
  const waldorfComponent = data.imageWithText.edges.filter(item => item.node.frontmatter.identifier === 'waldorf')
  const bikeComponent = data.imageWithText.edges.filter(item => item.node.frontmatter.identifier === 'bike')
  const snowComponent = data.imageWithText.edges.filter(item => item.node.frontmatter.identifier === 'snow')
  const diningComponent = data.imageWithText.edges.filter(item => item.node.frontmatter.identifier === 'dining')
  const garyJeffComponent = data.imageWithText.edges.filter(item => item.node.frontmatter.identifier === 'garyjeff')
  const clintonComponent = data.imageWithText.edges.filter(item => item.node.frontmatter.identifier === 'clintons')
  const familyWeddingComponent = data.imageWithText.edges.filter(item => item.node.frontmatter.identifier === 'familywedding')
  const flowerComponent = data.imageWithText.edges.filter(item => item.node.frontmatter.identifier === 'flower')
  const stampsComponent = data.imageWithText.edges.filter(item => item.node.frontmatter.identifier === 'stamps')
  const businessComponent = data.imageWithText.edges.filter(item => item.node.frontmatter.identifier === 'business')

  const becomingIndustrialComponent = data.imageWithHeading.edges.filter(item => item.node.frontmatter.identifier === 'becomingindustrial')
  const elizerspiritComponent = data.imageWithHeading.edges.filter(item => item.node.frontmatter.identifier === 'elizerspirit')
  const bypayinghisComponent = data.imageWithHeading.edges.filter(item => item.node.frontmatter.identifier === 'bypayinghis')

  const checkImage = data.imageGrid.edges.filter(item => item.node.frontmatter.identifier === 'checkImage')
  const storeOpening = data.imageGrid.edges.filter(item => item.node.frontmatter.identifier === 'storeOpening')
  const familyFoto = data.imageGrid.edges.filter(item => item.node.frontmatter.identifier === 'familyFoto')
  const jeffrey2000 = data.imageGrid.edges.filter(item => item.node.frontmatter.identifier === 'jeffrey2000')
  const fulllife = data.imageGrid.edges.filter(item => item.node.frontmatter.identifier === 'fulllife')
  const portrait = data.imageGrid.edges.filter(item => item.node.frontmatter.identifier === 'portrait')

  const positions = {
    rowOneWrapper: {
      classes: "mb-200 row-wrapper one",
      TextRow1: {
        classes: "",
          text1: {
            value: "Taking",
            classes: "fifty"
          },
          text2: {
            value: "",
            classes: "one"
          }
      },
      TextRow2: {
        classes: "center",
          text1: {
            value: "Big",
            classes: "twenty text-right"
          },
          text2: {
            value: "Risks",
            classes: "fifty text-right"
          }
      },
    },
    rowTwoWrapper: {
      classes: "row-wrapper two",
      TextRow1: {
        classes: "",
          text1: {
            value: "On",
            classes: "ten right-auto pl100"
          },
          text2: {
            value: "",
            classes: "twenty"
          }
      },
      TextRow2: {
        classes: "",
          text1: {
            value: "Untried",
            classes: "fifty"
          },
          text2: {
            value: "",
            classes: "empty"
          }
      },
      TextRow3: {
        classes: "",
          text1: {
            value: "",
            classes: "empty"
          },
          text2: {
            value: "Ideas",
            classes: "thirty Leftauto"
          }
      },
    }
  }



  return (
    <>
      <Seo title={"Eli Broad | Personal life"} description={"The story of Eli Broad is rooted in generations of a family defined by perseverance, entrepreneurship, curiosity and deep connection to community. It is a testament to relentless hard work, instinct and intelligence, and the ever-elusive luck."} />

      <Hero heroimage={hero} heroimagealt={heroAlt} identifier={identifier} positions={positions} description={heroDescription} />

      <BGImg content={storyHistorContent} />

      <BGImg content={familyHistorContent} />

      <TwoColumn content={twoColumnHeritageTitle} type="top" />

      <TwoColumn content={twoColumnHeritage} type="bottom" />

      <ImageWithHeading content={elizerspiritComponent} />

      <BGImg content={childhoodContent} />

      <TwoColumn content={twoColumnNyNj} noPadding={true} />

      <ImageWithText content={nynjComponent} alignTop={true} imageFloat={true} />

      <ImageWithHeading content={becomingIndustrialComponent} />

      <TwoColumn content={twoColumnDetroit} />

      <ImageGrid content={checkImage} />

      <TwoColumn content={twoColumnEconomicDepths} />

      <ImageGrid content={storeOpening} />

      <BGImg content={collegeContent} />

      <TwoColumn content={twoColumnSpringboard}/>

      <ImageWithText content={stampsComponent} />

      <ImageWithText content={msuComponent} />

      <ImageWithHeading content={bypayinghisComponent} />

      <TwoColumn content={twoColumnInternship}/>

      <BGImg content={familyFourContent} />

      <TwoColumn content={twoColumnPhoneCall} noPadding={true} />

      <ImageWithText content={waldorfComponent} alignTop={true} imageFloat={true} />

      <ImageWithText content={weddingComponent} />

      <ImageWithText content={familyWeddingComponent} />

      {/* <ImageGrid content={familyFoto} /> */}

      {/* <TwoColumn content={jeffreyAndGary} noPadding={true} /> */}

      <ImageWithText content={bikeComponent} imageFloat={false} />

      <ImageWithText content={snowComponent} imageFloat={false} />

      <ImageWithText content={diningComponent} imageFloat={false} />

      <ImageGrid content={fulllife} />

      {/* <ImageWithText content={garyJeffComponent} imageFloat={true} /> */}

      {/* <ImageGrid content={jeffrey2000} /> */}

      <TwoColumn content={aFullLife} />

      <ImageWithText content={clintonComponent} />

      <ImageWithText content={flowerComponent} />

      <ImageWithText content={businessComponent} headerContent={true} />

      <ImageGrid content={portrait} />


      <div class="continue-link">
        <Link to="/business">Continue to Business</Link>
      </div>

    </>
  )
}

export default PersonalLife

export const query = graphql`
  {
    hero: allMarkdownRemark(filter: {frontmatter: {identifier: {eq: "personal"}}}) {
      edges {
        node {
          frontmatter {
            heroImage {
              childImageSharp {
                gatsbyImageData(formats: AUTO, layout: FULL_WIDTH, placeholder: BLURRED)
              }
            }
            centeredImage {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, formats: AUTO)
              }
            }
            heroImageAlt
            identifier
            intro
            title
            description
          }
        }
      }
    }
    imageWithText: allMarkdownRemark(filter: {frontmatter: {component: {eq: "imageWithText"}, page: {eq: "personal"}}}) {
      edges {
        node {
          ...imageWithTextQuery
        }
      }
    }
    imageWithHeading: allMarkdownRemark(
      filter: {frontmatter: {component: {eq: "imageWithHeading"}, page: {eq: "personal"}}}
    ) {
      edges {
        node {
          ...imageWithHeadingQuery
        }
      }
    }
    imageGrid: allMarkdownRemark(filter: {frontmatter: {page: {eq: "personal"}, component: {eq: "imageGrid"}}}) {
      edges {
        node {
          ...imageGridQuery
        }
      }
    }
    largeBGImg: allMarkdownRemark(filter: {frontmatter: {component: {eq: "LargeBGImg"}, page: {eq: "personal"}}}) {
      edges {
        node {
          ...largeBGImgQuery
        }
      }
    }
    twoColumn: allMarkdownRemark(filter: {frontmatter: {page: {eq: "personal"}, component: {eq: "twocolumn"}}}) {
      edges {
        node {
          ...twoColumnQuery
        }
      }
    }
  }
`

